import {
  createRedirect,
  createLogin,
  createLogout,
  createResetPasswordSubmit,
  createResetPasswordRequest,
  createResetPasswordSuccess,
  createNavigation,
  Error500,
  NotFound404,
  createDummy,
  createAcceptInvitation,
  createMembersAndInvitations,
  createOrganizationApplicationList,
  createOrganizationApplicationShowPage,
  createOrganizationSinglePage,
  createMenuItemDefault,
  createSideNavigationItemDefault,
  createListSettingsPage,
  createChangeEmail,
  createChangePassword,
  createVerifyChangeEmailSuccess,
  createAllOrganizationsList,
  createChangeName,
  createChangePhoneNumber,
  loadDefaultTranslations,
  loadTranslationsFromYaml,
  ToastsDisplay,
  Template,
  TemplateOpts,
  defaultApiErrorHandler,
  defaultErrorMessageI18nHandler,
  initializeAutoRefreshToken,
  initializeServiceVersionCheckers,
  isLoggedIn,
  isNotLoggedIn,
  createMenuItemUserInfo,
  Link,
  LoadingPage,
  // createSideNavigationItemExternalUrl,
} from '@basaldev/blocks-frontend-framework';
import { api, log, session, utils } from '@basaldev/blocks-frontend-sdk';
import merge from 'lodash.merge';
import partial from 'lodash.partial';

import logoUrl from './image/geekle.svg';
import translationOverrides from './translationOverrides.yaml';

interface GeekleAdminAppTemplateDependencies {
  /** Auth api client */
  authApi: Pick<
    api.AuthApi,
    | 'getOAuthGoogleLoginUrl'
    | 'login'
    | 'refresh'
    | 'logout'
    | 'onetimeTokenLogin'
    | 'getRequiredVersion'
    | 'ping'
  >;
  /** The URL for the organization page */
  demandSiteOrganizationUrl: string;
  /** Organization api client */
  organizationApi: Pick<
    api.OrganizationApi,
    | 'listOrganizations'
    | 'listOrganizationsForUser'
    | 'getOrganization'
    | 'updateOrganization'
    | 'ping'
    | 'getRequiredVersion'
  >;
  /** Session service for managing user sessions */
  sessionService: session.SessionService;
  /** User data api client */
  userApi: Pick<
    api.UserApi<unknown>,
    | 'acceptInvitation'
    | 'getUser'
    | 'resetPassword'
    | 'sendResetPasswordEmail'
    | 'sendVerificationEmail'
    | 'updateUser'
    | 'verifyEmail'
    | 'changeEmail'
    | 'verifyChangeEmail'
    | 'changePassword'
    | 'deactivateUser'
    | 'getRequiredVersion'
    | 'ping'
    | 'acceptInvitation'
    | 'createInvitation'
    | 'deleteInvitation'
    | 'listInvitations'
    | 'listUsers'
    | 'deleteUser'
    | 'lockUser'
    | 'unlockUser'
  >;
}

export class GeekleAdminAppTemplate implements Template {
  opts: Required<TemplateOpts>;
  dependencies: GeekleAdminAppTemplateDependencies;

  constructor(
    opts: TemplateOpts,
    dependencies: GeekleAdminAppTemplateDependencies
  ) {
    this.opts = merge<Required<TemplateOpts>, TemplateOpts>(
      {
        apiErrorHandler: partial(defaultApiErrorHandler, dependencies.authApi),
        appInitialization: [
          partial(initializeAutoRefreshToken, dependencies.authApi, {
            refreshIntervalMs: 5 * 60 * 1000,
          }),
          partial(initializeServiceVersionCheckers, [
            dependencies.authApi,
            dependencies.userApi,
            dependencies.organizationApi,
          ]),
        ],
        appInitializationLoader: LoadingPage,
        appName: 'geekle-admin-app',
        blockPages: [
          {
            component: createRedirect({
              options: { replace: true },
              to: 'applications.index',
            }),
            name: 'top',
            path: '/',
          },
          {
            component: createAcceptInvitation({
              passwordValidateStrategy: utils.defaultPasswordValidateStrategy,
              redirectAfterAcceptRoute: 'auth.login',
              userApi: dependencies.userApi,
              userTypeId: '100',
            }),
            name: 'auth.accept-invitation',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:acceptInvitationPageTitle'),
            path: '/auth/accept-invitation/:invitationId/:token',
            validators: {
              isNotLoggedIn: isNotLoggedIn({
                loggedInRedirect: 'applications.index',
              }),
            },
          },
          {
            component: createOrganizationApplicationShowPage({
              organizationApi: dependencies.organizationApi,
            }),
            name: 'applications.show',
            navigationOptions: {
              type: 'singleItem',
            },
            pageTitle: {
              fallback: (t) => t('Organization:applicationShowPageTitle'),
              // eslint-disable-next-line @typescript-eslint/naming-convention
              fetch: async (blockProps, _t, updatePageTitle) => {
                if (!blockProps.params.applicationId) {
                  return;
                }
                const org = await dependencies.organizationApi.getOrganization({
                  orgId: blockProps.params.applicationId,
                });
                if (org.name) {
                  updatePageTitle(org.name);
                }
              },
            },
            parentBlockPath: 'applications.index',
            path: '/applications/:applicationId',
            validators: {
              isLoggedIn: isLoggedIn({ notLoggedInRedirect: 'auth.login' }),
            },
          },
          {
            component: createLogin({
              alreadyVerifiedRoute: 'applications.index',
              authApi: dependencies.authApi,
              showSignupLink: false,
              userApi: dependencies.userApi,
            }),
            name: 'auth.login',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:loginPageTitle'),
            path: '/auth/login',
          },
          {
            component: createResetPasswordRequest({
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-request',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:resetPasswordRequestPageTitle'),
            parentBlockPath: 'auth.login',
            path: '/auth/reset-password-request',
          },
          {
            component: createResetPasswordSubmit({
              passwordValidateStrategy: utils.defaultPasswordValidateStrategy,
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-submit',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:resetPasswordSubmitPageTitle'),
            path: '/auth/reset-password-submit/:resetPasswordToken',
          },
          {
            component: createResetPasswordSuccess({
              userApi: dependencies.userApi,
            }),
            name: 'auth.reset-password-success',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Auth:resetPasswordSuccessPageTitle'),
            path: '/auth/reset-password-success',
          },
          {
            component: createLogout({
              authApi: dependencies.authApi,
              logoutRedirect: '/auth/login',
            }),
            name: 'auth.logout',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            path: '/auth/logout',
          },
          {
            component: createOrganizationApplicationList({
              organizationApi: dependencies.organizationApi,
              showOrganizationApplicationRoute: 'applications.show',
            }),
            name: 'applications.index',
            pageTitle: (t) => t('Organization:applicationListPageTitle'),
            path: '/applications',
            validators: {
              isLoggedIn: isLoggedIn({ notLoggedInRedirect: 'auth.login' }),
            },
          },
          {
            component: createOrganizationSinglePage({
              demandSiteOrganizationUrl: `${dependencies.demandSiteOrganizationUrl}`,
              organizationApi: dependencies.organizationApi,
            }),
            name: 'organization.show',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: {
              fallback: (t) => t('Organization:applicationShowPageTitle'),
              // eslint-disable-next-line @typescript-eslint/naming-convention
              fetch: async (blockProps, _t, updatePageTitle) => {
                if (!blockProps.params.orgId) {
                  return;
                }
                const org = await dependencies.organizationApi.getOrganization({
                  orgId: blockProps.params.orgId,
                });
                if (org.name) {
                  updatePageTitle(org.name);
                }
              },
            },
            parentBlockPath: 'organizations.index',
            path: '/organizations/:orgId',
            validators: {
              isLoggedIn: isLoggedIn({ notLoggedInRedirect: 'auth.login' }),
            },
          },
          {
            component: createAllOrganizationsList({
              organizationApi: dependencies.organizationApi,
              pageSize: 20,
              showOrganizationApplicationRoute: '/applications/:organizationId',
              showOrganizationRoute: '/organizations/:organizationId',
            }),
            name: 'organizations.index',
            pageTitle: (t) => t('Organization:allOrganizations.listPageTitle'),
            path: '/organizations',
            validators: {
              isLoggedIn: isLoggedIn({ notLoggedInRedirect: 'auth.login' }),
            },
          },
          {
            component: createDummy({ text: 'TODO: Dummy Users' }),
            name: 'users.index',
            pageTitle: (t) => t('User:userListPageTitle'),
            path: '/users',
            validators: {
              isLoggedIn: isLoggedIn({ notLoggedInRedirect: 'auth.login' }),
            },
          },
          {
            component: createMembersAndInvitations({
              logoutRoute: 'auth.logout',
              userApi: dependencies.userApi,
            }),
            name: 'members.index',
            pageTitle: (t) => t('User:memberListPageTitle'),
            path: '/members',
            validators: {
              isLoggedIn: isLoggedIn({ notLoggedInRedirect: 'auth.login' }),
            },
          },
          {
            component: createListSettingsPage({
              changeEmailRoute: 'settings.change-email',
              changeNameRoute: 'settings.change-name',
              changePasswordRoute: 'settings.change-password',
              changePhoneNumberRoute: 'settings.change-phone-number',
              userApi: dependencies.userApi,
            }),
            name: 'settings',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Profile:ProfileMenuItemLabel'),
            parentBlockPath: 'applications.index',
            path: '/settings',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createChangeEmail({
              cancelRoute: 'settings',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-email',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Settings:changeEmail.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-email',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createChangeName({
              cancelRoute: 'settings',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-name',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Settings:changeName.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-name',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createChangePhoneNumber({
              cancelRoute: 'settings',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-phone-number',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Settings:changePhoneNumber.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-phone-number',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
          {
            component: createVerifyChangeEmailSuccess({
              userApi: dependencies.userApi,
              verifiedRoute: 'settings',
            }),
            name: 'settings.verify-change-email-success',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'noMenu',
            },
            pageTitle: (t) => t('Settings:verifyEmail.title'),
            path: '/settings/verify-change-email-success/:verifyChangeEmailToken',
          },
          {
            component: createChangePassword({
              cancelRoute: 'settings',
              forgotPasswordUrl: 'auth.reset-password-request',
              successRoute: 'settings',
              userApi: dependencies.userApi,
            }),
            name: 'settings.change-password',
            navigationOptions: {
              hideSideNavigation: true,
              topBarType: 'singleItem',
            },
            pageTitle: (t) => t('Settings:changePassword.title'),
            parentBlockPath: 'settings',
            path: '/settings/change-password',
            validators: {
              isLoggedIn: isLoggedIn({
                notLoggedInRedirect: 'auth.login',
              }),
            },
          },
        ],
        error500Component: Error500,
        errorMessageI18nHandler: defaultErrorMessageI18nHandler,
        i18nOptions: {
          resources: merge(
            loadDefaultTranslations(),
            loadTranslationsFromYaml(translationOverrides)
          ),
        },
        logger: new log.FrontendLogger({
          appName: opts.appName ?? 'geekle-admin-app',
          // @ts-expect-error vite defines magic property
          env: import.meta.env.PROD ? 'production' : 'development',
        }),
        navigationComponent: createNavigation({
          headerLogoElement: (onNavigate) => (
            <Link href="/" onNavigate={onNavigate}>
              <img src={logoUrl} alt="Geekle logo" />
            </Link>
          ),
          menuNavigationBlocks: [
            createMenuItemUserInfo({
              userApi: dependencies.userApi,
            }),
            createMenuItemDefault({
              text: (t) => t('Profile:ProfileMenuItemLabel'),
              toRoute: 'settings',
            }),
            createMenuItemDefault({
              text: (t) => t('Auth:logoutButton'),
              toRoute: 'auth.logout',
            }),
          ],
          sideNavigationBlocks: [
            createSideNavigationItemDefault({
              icon: 'approval',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarApplications'),
              toRoute: 'applications.index',
            }),
            createSideNavigationItemDefault({
              icon: 'maps_home_work',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarOrganizations'),
              toRoute: 'organizations.index',
            }),
            createSideNavigationItemDefault({
              icon: 'computer',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarUsers'),
              toRoute: 'users.index',
            }),
            // createSideNavigationItemExternalUrl({
            //   href: 'https://dashboard.stripe.com/test/dashboard',
            //   icon: 'payments',
            //   requiresLogin: true,
            //   text: (t) => t('Geekle:sidebarInvoices'),
            // }),
            createSideNavigationItemDefault({
              icon: 'person',
              requiresLogin: true,
              text: (t) => t('Geekle:sidebarMembers'),
              toRoute: 'members.index',
            }),
          ],
        }),
        notFound404Component: NotFound404,
        pageTitleConfiguration: {
          appName: 'Geekle Admin',
        },
        screenConfiguration: {
          desktopCutoff: 950,
          enabledSizes: {
            bigDesktop: true,
            desktop: true,
            mobile: false,
          },
          mobileCutoff: 640,
        },
        theme: {
          configs: {
            timezone: 'Asia/Tokyo',
          },
        },
        toastConfiguration: {
          displayMs: 5000,
          toastComponent: ToastsDisplay,
        },
      },
      opts
    );
    this.dependencies = dependencies;
  }
}

import { log } from '@basaldev/blocks-frontend-sdk';

export class SentryLogger extends log.FrontendLogger {
  protected formatLogMessage(
    meta: log.LoggerMeta,
    param: unknown[],
    level: log.LogLevel
  ): [string, ...unknown[]] {
    const formattedMessage = super.formatLogMessage(meta, param, level);

    return formattedMessage;
  }
}
